import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index', //二维码
    name: 'TwoDimensionalCode',
    component: () => import('@/views/twoDimensionalCode/index.vue')
  },
  {
    path: '/agency', //二维码
    name: 'Agency',
    component: () => import('@/views/agency/index.vue')
  },
  {
    path: '/earnings', //二维码
    name: 'Earnings',
    component: () => import('@/views/earnings/index.vue')
  },
  {
    path: '/', //二维码
    name: 'freeTickets',
    component: () => import('@/views/concert/freeTickets.vue')
  },
  {
    path: '/concert/myInvitation', //我的邀请
    name: 'myInvitation',
    component: () => import('@/views/concert/myInvitation.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
